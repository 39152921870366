import { UserQuota } from '@services/userQuota/constants'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'

export const dataStorageFormatter = (params: ICellRendererParams<UserQuota>) => {
  const rowData = params.node.data
  if (!rowData) {
    return '--'
  }
  const dataStorage = Number(rowData.dataStorage) / 1e9
  const dataStorageLimit = Number(rowData.dataStorageLimit) / 1e9
  return `${dataStorage.toFixed(3)} / ${dataStorageLimit}`
}

export const timeFormatter = (param: ICellRendererParams<UserQuota>) => {
  return timestampToDate(param.value)
}

export const tlmTokenLimitFormatter = (params: ICellRendererParams<UserQuota>) => {
  const rowData = params.node.data
  if (!rowData) {
    return '--'
  }
  return `${rowData.numTlmTokensUsed} / ${rowData.tlmTokenLimit}`
}

export const columnDefs: ColDef[] = [
  {
    field: 'userId',
    headerName: 'User ID',
    pinned: 'left',
    checkboxSelection: true,
    minWidth: 200,
  },
  {
    field: 'name',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'email',
    headerName: 'Email',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'dataStorageLimit',
    headerName: 'Data Storage (GB)',
    cellRenderer: 'dataStorageFormatter',
  },
  {
    field: 'tlmTokenLimit',
    headerName: 'TLM Token Limit',
    cellRenderer: 'tlmTokenLimitFormatter',
  },
  {
    field: 'tlmTokenPerMinuteLimit',
    headerName: 'TLM Token Per Minute Limit',
  },
  {
    field: 'tlmPreviewModelsRequestLimit',
    headerName: 'TLM Preview Models Request Limit',
  },
  { field: 'createdAt', headerName: 'Created At', cellRenderer: 'timeFormatter' },
  { field: 'lastUpdated', headerName: 'Last Updated', cellRenderer: 'timeFormatter' },
]
